// Breakpoints
$smallScreen: 480px;
$mediumScreen: 768px;
$largeScreen: 992px;
$xLargeScreen: 1200px;

// Media Queries
$smallAbove: "screen and (min-width: #{$smallScreen})";
$mediumAbove: "screen and (min-width: #{$mediumScreen})";
$largeAbove: "screen and (min-width: #{$largeScreen})";
$xLargeAbove: "screen and (min-width: #{$xLargeScreen})";

// Colors
:root {
  // Brand Colors
  --primary: #13233d;
  --primary-rgb: 19, 35, 61;
  --secondary: #0552ac;
  --secondary-rgb: 5, 82, 172;
  --accent: #325fec;
  --accent-rgb: 50, 95, 236;
  --lime: #d1d972;
  --lime-rgb: 209, 217, 114;
  --gray: #a7acb0;
  --gray-rgb: 167, 172, 176;

  // Text Colors
  --header: var(--primary);
  --paragraphs: rgba(var(--primary-rgb), 0.8);
  --muted: var(--gray);

  // Background Colors
  --background: #ffffff;
  --background-alt: rgba(var(--accent-rgb), 0.03);
  --border: rgba(var(--primary-rgb), 0.1);

  // Utility Colors
  --white: #ffffff;
  --black: #000000;
  --error: #ff4444;
  --success: #00c851;
  --warning: #ffbb33;

  // Shadows
  --shadow-sm: 0 2px 4px rgba(var(--primary-rgb), 0.05);
  --shadow-md: 0 4px 8px rgba(var(--primary-rgb), 0.1);
  --shadow-lg: 0 8px 16px rgba(var(--primary-rgb), 0.15);

  // Spacing
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;

  // Border Radius
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 16px;
  --radius-full: 9999px;

  // Typography
  --font-family: "Gabarito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  --line-height-tight: 1.2;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;

  // Z-index
  --z-header: 100;
  --z-modal: 1000;
  --z-tooltip: 1500;

  // Brand Gradients
  --gradient-primary: linear-gradient(
    135deg,
    var(--secondary) 0%,
    var(--accent) 100%
  );
  --gradient-accent: linear-gradient(
    135deg,
    var(--accent) 0%,
    var(--lime) 100%
  );
  --gradient-muted: linear-gradient(
    135deg,
    var(--gray) 0%,
    rgba(var(--gray-rgb), 0.5) 100%
  );
}
