@import "../../../styles/variables.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-header);
  padding: 1.25rem 0;
  transition: all 0.3s ease;
  background: transparent;

  &.sticky {
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(8px);
    box-shadow: 0 1px 0 0 rgba(var(--primary-rgb), 0.05);
  }

  &__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$mediumAbove} {
      padding: 0 2rem;
    }
  }

  &__logo {
    img {
      width: 125px;

      @media #{$mediumAbove} {
        width: 150px;
      }
    }
  }

  &__nav {
    display: none;
    gap: 2.5rem;

    @media #{$mediumAbove} {
      display: flex;
    }

    a {
      color: var(--header);
      text-decoration: none;
      font-weight: 500;
      font-size: 1rem;
      transition: color 0.2s ease;

      &:hover {
        color: var(--accent);
      }
    }
  }

  &__cta {
    .button {
      padding: 0.625rem 1.25rem;
      font-size: 0.9375rem;

      @media screen and (max-width: 350px) {
        display: none;
      }
    }
  }
}

.hero {
  min-height: 100vh;
  padding: 5rem 0 2rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    160deg,
    #f5f9ff 0%,
    rgba(255, 255, 255, 0.95) 100%
  );

  @media #{$mediumAbove} {
    padding: 7rem 0 4rem;
  }

  &__background {
    position: fixed;
    inset: 0;
    z-index: 0;
    pointer-events: none;
  }

  &__gradient {
    position: absolute;
    inset: 0;
    background: linear-gradient(160deg, #d1d972, #fff, #0552ac);
    opacity: calc(0.9 - (var(--scroll-progress, 0) * 0.5));
    transition: opacity 0.6s ease;
  }

  &__expanding-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vh;
    height: 100vh;
    background: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
    transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1),
      opacity 1.2s cubic-bezier(0.4, 0, 0.2, 1);

    .hero--expanded & {
      transform: translate(-50%, -50%) scale(5);
      opacity: 1;
    }
  }

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;

    @media #{$mediumAbove} {
      padding: 0 2rem;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    margin-bottom: 0;
    padding-bottom: 60px;
    align-items: center;
    margin-top: 16px;

    @media #{$mediumAbove} {
      gap: 4rem;
      padding-bottom: 100px;
      margin-top: 0;
    }

    @media #{$xLargeAbove} {
      grid-template-columns: 1fr 1fr;
      gap: 6rem;
    }
  }

  &__text {
    max-width: 100%;
    z-index: 1;
    text-align: center;

    @media #{$mediumAbove} {
      text-align: left;
      max-width: 720px;
    }

    @media #{$xLargeAbove} {
      max-width: 600px;
    }
  }

  &__title {
    font-size: 2.5rem;
    line-height: 1.1;
    letter-spacing: -0.04em;
    margin-bottom: 1rem;

    > span {
      margin-left: 8px;

      @media #{$xLargeAbove} {
        margin-left: 0;
      }
    }

    @media #{$mediumAbove} {
      font-size: 4rem;
      margin-bottom: 1.5rem;
    }

    @media #{$largeAbove} {
      font-size: 5rem;
    }

    br {
      display: none;
      @media #{$xLargeAbove} {
        display: block;
      }
    }
  }

  &__description {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;

    @media #{$mediumAbove} {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }

    @media #{$largeAbove} {
      font-size: 1.5rem;
    }
  }

  &__cta-group {
    display: flex;
    justify-content: center;
    gap: 1rem;

    @media #{$mediumAbove} {
      justify-content: flex-start;
      gap: 2rem;
    }
  }

  &__visuals {
    position: relative;

    @media #{$xLargeAbove} {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-height: 620px) {
      display: none;
    }
  }

  &__phone {
    width: 100%;
    height: 48vh;
    margin: 0 auto;

    @media #{$xLargeAbove} {
      max-width: 320px;
      aspect-ratio: 0.5;
      width: calc((100vh - 9rem) * 0.5);
      height: calc(100vh - 9rem);
      max-width: none;
      margin: 0;
    }
  }

  &__phone-frame {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #c9c9cd;
    border-radius: 48px;
    padding: 12px;

    overflow: hidden;

    @media #{$mediumAbove} {
      background: #1d1d1d;
      box-shadow: 0 48px 100px rgba(0, 0, 0, 0.25);
      border: none;
    }

    &::before {
      @media #{$mediumAbove} {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 40%;
        height: 24px;
        background: #1d1d1d;
        border-radius: 0 0 16px 16px;
        z-index: 2;
      }
    }
  }

  &__phone-screen {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 36px;
    overflow: hidden;
  }

  &__phone-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__floating-elements {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  &__notification {
    @media #{$mediumAbove} {
      display: flex;
      width: 280px;
    }

    @media #{$smallAbove} {
      display: flex;
      width: 240px;
    }

    position: absolute;
    background: rgba(255, 255, 255, 0.98);
    border-radius: 16px;
    padding: 1.25rem;
    box-shadow: 0 24px 48px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    transform: translateY(20px);
    border: 1px solid rgba(var(--accent-rgb), 0.1);

    &--settlement-1 {
      top: 15%;
      left: 5%;
      width: 280px;
      transition-delay: 0.2s;
    }

    &--settlement-2 {
      top: 40%;
      left: 50%;
      width: 280px;
      transition-delay: 0.4s;
    }

    &--settlement-3 {
      top: 65%;
      left: 5%;
      width: 280px;
      transition-delay: 0.6s;
    }
  }

  &__notification-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 10px;
    background: rgba(var(--accent-rgb), 0.1);
    color: var(--accent);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__notification-content {
    flex: 1;
  }

  &__notification-title {
    font-weight: 600;
    color: var(--header);
    margin-bottom: 0.25rem;
  }

  &__notification-subtitle {
    font-size: 0.875rem;
    color: var(--paragraphs);
  }

  &__processing {
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);

    &-spinner {
      width: 40px;
      height: 40px;
      border: 3px solid rgba(var(--accent-rgb), 0.1);
      border-top-color: var(--accent);
      border-radius: 50%;
      margin: 0 auto 1rem;
      animation: spin 1s linear infinite;
    }

    &-text {
      font-size: 1.125rem;
      color: var(--header);
      font-weight: 500;
    }
  }

  &__completed {
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);

    &-icon {
      width: 64px;
      height: 64px;
      background: var(--gradient-primary);
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      margin: 0 auto 1rem;
    }

    &-text {
      font-size: 1.5rem;
      color: var(--header);
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    &-amount {
      font-size: 2rem;
      color: var(--accent);
      font-weight: 700;
    }
  }

  &.in-view .hero__notification {
    opacity: 1;
    transform: translateY(0);
    z-index: 2;
  }

  &[data-state="selecting"] {
    .hero__notification--settlement-1,
    .hero__notification--settlement-3 {
      opacity: 0.5;
      transform: scale(0.9);
      filter: blur(2px);
    }

    .hero__notification--settlement-2 {
      transform: scale(1.1);
      box-shadow: 0 32px 64px rgba(0, 0, 0, 0.2);
      z-index: 10;
    }
  }

  &[data-state="selected"] {
    .hero__notification--settlement-1,
    .hero__notification--settlement-3 {
      opacity: 0;
      transform: scale(0.8);
      filter: blur(4px);
    }

    .hero__notification--settlement-2 {
      transform: scale(1.2) translateX(-115px);
      box-shadow: 0 32px 64px rgba(0, 0, 0, 0.2);
      border: 1px solid blue;
      z-index: 10;
    }
  }

  &[data-state="pause"] {
    .hero__notification {
      transform: scale(0.9) translateX(-115px);
      box-shadow: 0 32px 64px rgba(0, 0, 0, 0.2);
      opacity: 0;
    }
  }

  &[data-state="processing"] {
    .hero__notification {
      opacity: 0;
      transform: scale(0.9);
    }

    .hero__processing {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &[data-state="completed"] {
    .hero__notification {
      opacity: 0;
      transform: scale(0.8);
    }

    .hero__completed {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__extended-content {
    padding: 4rem 1.25rem 2rem;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    position: relative;
    z-index: 1;
    perspective: 1000px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 1px;
      background: linear-gradient(
        90deg,
        transparent 0%,
        rgba(var(--accent-rgb), 0.2) 25%,
        rgba(var(--accent-rgb), 0.2) 75%,
        transparent 100%
      );
      animation: separatorPulse 3s ease-in-out infinite;
    }

    @media #{$mediumAbove} {
      padding: 8rem 2rem 4rem;
    }
  }

  &__story {
    opacity: 0;
    transform: translateY(30px) rotateX(10deg);
    transform-origin: top;
    transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;

    &.is-visible {
      opacity: 1;
      transform: translateY(0) rotateX(0);
    }

    p {
      opacity: 0;
      transform: translateY(20px);
      transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
      margin-bottom: 1.25rem;

      @media #{$mediumAbove} {
        font-size: 1.2rem;
      }

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transition-delay: #{$i * 0.2}s;
        }
      }

      &.is-visible {
        opacity: 1;
        transform: translateY(0);
      }

      &::before {
        display: none;

        @media #{$mediumAbove} {
          display: block;
          content: "";
          position: absolute;
          left: -2rem;
          top: 0.5rem;
          width: 4px;
          height: 0;
          background: linear-gradient(
            to bottom,
            var(--accent),
            var(--secondary)
          );
          transition: height 0.8s cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0.8;
          border-radius: 2px;
        }
      }

      &.is-visible::before {
        height: calc(100% - 1rem);
      }
    }
  }

  &__founder {
    opacity: 0;
    transform: translateY(40px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    padding: 1.5rem;
    border-radius: 16px;
    background: linear-gradient(
      135deg,
      rgba(var(--accent-rgb), 0.05),
      rgba(var(--secondary-rgb), 0.05)
    );

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
      animation: cardFloat 6s ease-in-out infinite;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 20px 40px rgba(var(--accent-rgb), 0.1);

      &::before {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, var(--accent), var(--secondary));
      transform: translateX(-100%);
      opacity: 0;
      transition: all 0.6s ease;
    }

    h3 {
      font-size: 1.5rem;
      color: var(--header);
      margin-bottom: 1.5rem;

      @media #{$mediumAbove} {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.8;
      color: var(--paragraphs);
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__steps {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }

    h3 {
      font-size: 1.5rem;
      color: var(--header);
      margin-bottom: 2rem;
      text-align: center;

      @media #{$mediumAbove} {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }
  }

  &__steps-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media #{$mediumAbove} {
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
    }
  }

  &__step {
    background: var(--white);
    border-radius: var(--radius-lg);
    padding: 1.5rem;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateY(20px);
    box-shadow: 0 8px 32px -4px rgba(var(--primary-rgb), 0.08),
      0 2px 8px -2px rgba(var(--primary-rgb), 0.06);
    position: relative;
    isolation: isolate;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(
        135deg,
        rgba(var(--accent-rgb), 0.1),
        rgba(var(--secondary-rgb), 0.1)
      );
      border-radius: inherit;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 20px 40px -8px rgba(var(--accent-rgb), 0.15),
        0 8px 16px -4px rgba(var(--primary-rgb), 0.1);

      &::before {
        opacity: 1;
      }

      .hero__step-number {
        background: var(--gradient-primary);
        .hero__icon {
          color: var(--white);
          transform: scale(1.1);
        }
      }
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i * 0.1}s;
      }
    }
  }

  &__step-number {
    width: 48px;
    height: 48px;
    border-radius: var(--radius-md);
    background: rgba(var(--accent-rgb), 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;

    .hero__icon {
      width: 24px;
      height: 24px;
      color: var(--accent);
      transition: all 0.3s ease;
    }
  }

  &__step h4 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    color: var(--header);
    font-weight: 600;
  }

  &__step p {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--paragraphs);
    margin: 0;
  }

  &__newsletter {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    padding: 1.5rem;
    border-radius: 16px;

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
      animation: cardFloat 6s ease-in-out infinite;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(
        45deg,
        transparent,
        rgba(var(--accent-rgb), 0.1),
        transparent
      );
      background-size: 200% 200%;
      animation: shimmerEffect 3s infinite linear;
      z-index: -1;
    }

    h3 {
      font-size: 1.5rem;
      color: var(--header);
      margin-bottom: 1.5rem;

      @media #{$mediumAbove} {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.8;
      color: var(--paragraphs);
      margin-bottom: 2rem;
      max-width: 600px;

      @media #{$mediumAbove} {
        font-size: 1.125rem;
      }
    }
  }

  .text-gradient {
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 0.9375rem;
  text-decoration: none;
  transition: all 0.2s ease;

  @media #{$mediumAbove} {
    padding: 0.875rem 1.75rem;
    font-size: 1rem;
  }

  &--primary {
    background: var(--gradient-primary);
    color: white;
    padding: 0.875rem 1.75rem;
    font-size: 1rem;

    @media #{$mediumAbove} {
      padding: 1rem 2rem;
      font-size: 1.0625rem;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 16px rgba(var(--accent-rgb), 0.24);
    }
  }

  &--secondary {
    background: rgba(var(--accent-rgb), 0.1);
    color: var(--accent);

    &:hover {
      background: rgba(var(--accent-rgb), 0.15);
    }
  }

  &--text {
    color: #1d1d1d;
    padding: 1rem 0;
    font-size: 1.0625rem;

    &:hover {
      color: #0666eb;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.hero.in-view {
  --title-opacity: 1;
  --title-offset: 0;
  --description-opacity: 1;
  --description-offset: 0;
  --cta-opacity: 1;
  --cta-offset: 0;
  --content-offset: 0;
  --visuals-offset: 0;
  --card1-opacity: 1;
  --card1-offset: 0;
  --card1-scale: 1;
  --card1-rotate: 0deg;
  --card2-opacity: 1;
  --card2-offset: 0;
  --card2-scale: 1;
  --card2-rotate: 0deg;
  --card3-opacity: 1;
  --card3-offset: 0;
  --card3-scale: 1;
  --card3-rotate: 0deg;
}

@keyframes shimmer {
  0% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  100% {
    transform: translate(50%, 50%) rotate(45deg);
  }
}

@keyframes separatorPulse {
  0%,
  100% {
    opacity: 0.5;
    width: 90vw;
  }
  50% {
    opacity: 1;
    width: 100vw;
  }
}

@keyframes cardFloat {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes shimmerEffect {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.footer {
  padding: 1.5rem 0;
  background: var(--white);
  position: relative;
  z-index: 1;
  border-top: 1px solid rgba(var(--primary-rgb), 0.08);

  @media #{$mediumAbove} {
    padding: 2rem 0;
  }

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    @media #{$mediumAbove} {
      padding: 0 2rem;
    }
  }

  &__copyright {
    color: var(--paragraphs);
    font-size: 0.875rem;
  }

  &__social {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }

  &__social-link {
    color: var(--paragraphs);
    transition: all 0.2s ease;

    &:hover {
      color: var(--accent);
      transform: translateY(-2px);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
