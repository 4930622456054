@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&display=swap");

@import "./variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-size: 1rem;
  line-height: var(--line-height-normal);
  color: var(--paragraphs);
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header);
  line-height: var(--line-height-tight);
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 2.5rem;
  letter-spacing: -0.02em;

  @media #{$mediumAbove} {
    font-size: 3.5rem;
  }

  @media #{$largeAbove} {
    font-size: 4rem;
  }
}

h2 {
  font-size: 2rem;
  letter-spacing: -0.01em;

  @media #{$mediumAbove} {
    font-size: 2.5rem;
  }

  @media #{$largeAbove} {
    font-size: 3rem;
  }
}

h3 {
  font-size: 1.5rem;

  @media #{$mediumAbove} {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1.25rem;

  @media #{$mediumAbove} {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin: 0;
  line-height: var(--line-height-relaxed);
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s ease;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
}

// Container
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);

  @media #{$mediumAbove} {
    padding: 0 var(--spacing-lg);
  }

  @media #{$largeAbove} {
    padding: 0 var(--spacing-xl);
  }
}

// Grid System
.grid {
  display: grid;
  gap: var(--spacing-md);

  @media #{$mediumAbove} {
    grid-template-columns: repeat(12, 1fr);
    gap: var(--spacing-lg);
  }
}

// Utility Classes
.text-gradient {
  background: linear-gradient(135deg, var(--blue-1) 0%, var(--blue-2) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

// Animation Classes
.animate {
  &--fade-in {
    animation: fadeIn 0.5s ease forwards;
  }

  &--slide-up {
    animation: slideUp 0.5s ease forwards;
  }

  &--scale-in {
    animation: scaleIn 0.5s ease forwards;
  }
}
