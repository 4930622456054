.navbar {
  position: fixed;
  width: 100%;
  padding: 1.25rem 0;
  z-index: 1000;

  &.scrolled {
    @media #{$extraLargeBelow} {
      backdrop-filter: blur(20px);
      background-color: rgba(243, 244, 245, 0.8);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(10, 24, 55, 0.15);
    }
  }

  &__logo {
    width: 8em;
    margin-left: 2em;
    cursor: pointer;
  }
}
