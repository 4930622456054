@import "../../styles/variables.scss";

.signup-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    160deg,
    #f5f9ff 0%,
    rgba(255, 255, 255, 0.95) 100%
  );

  .loading-overlay {
    position: fixed;
    inset: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(var(--accent-rgb), 0.1);
    border-top-color: var(--accent);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .hero {
    margin-top: 2rem;
    padding: 3rem 0 0;
    position: relative;
    min-height: auto;

    @media #{$mediumAbove} {
      margin-top: 3rem;
      padding: 4rem 0 2rem;
    }

    &__content {
      margin: 0 auto;
      padding: 0 1.25rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media #{$mediumAbove} {
        padding: 0 2rem;
      }

      &__heading {
        font-size: 2.25rem;
        line-height: 1.1;
        letter-spacing: -0.04em;
        margin-bottom: 0.75rem;
        color: var(--header);

        @media #{$mediumAbove} {
          font-size: 3.5rem;
          margin-bottom: 1rem;
        }
      }

      &__paragraph {
        font-size: 1rem;
        line-height: 1.5;
        color: var(--paragraphs);
        margin-bottom: 1rem;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;

        @media #{$mediumAbove} {
          font-size: 1.125rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .content {
    max-width: 90vw;

    @media #{$mediumAbove} {
      max-width: 50em;
    }
  }

  .form-section {
    flex: 1;
    padding: 1rem;

    &__form-wrapper {
      max-width: 600px;
      margin: 0 auto;
      padding: 1.5rem;
      background: var(--white);
      border-radius: var(--radius-lg);
      box-shadow: 0 8px 32px -4px rgba(var(--primary-rgb), 0.08),
        0 2px 8px -2px rgba(var(--primary-rgb), 0.06);

      @media #{$mediumAbove} {
        padding: 2rem;
      }
    }

    &__field-group {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.75rem;
      margin-bottom: 0.75rem;

      @media #{$mediumAbove} {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
    }

    &__field {
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__field-label {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--header);
      margin-bottom: 0.25rem;

      span {
        color: var(--paragraphs);
        font-weight: 400;
      }
    }

    input[type="text"],
    input[type="email"] {
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 1rem;
      line-height: 1.5;
      color: var(--header);
      background: var(--white);
      border: 1px solid rgba(var(--primary-rgb), 0.1);
      border-radius: var(--radius-md);
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: var(--accent);
        box-shadow: 0 0 0 3px rgba(var(--accent-rgb), 0.1);
      }

      &::placeholder {
        color: var(--paragraphs);
        opacity: 0.5;
      }
    }

    .submit-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0.875rem 1.5rem;
      font-size: 1rem;
      font-weight: 600;
      color: var(--white);
      background: var(--gradient-primary);
      border: none;
      border-radius: var(--radius-md);
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover:not(:disabled) {
        transform: translateY(-2px);
        box-shadow: 0 8px 16px rgba(var(--accent-rgb), 0.24);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &__error-message {
      background: rgba(255, 0, 0, 0.1);
      color: #d32f2f;
      padding: 0.75rem;
      border-radius: var(--radius-md);
      margin-bottom: 1rem;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    &__success-message-wrapper {
      text-align: center;
      padding: 1rem;
    }

    &__success-message-text {
      font-size: 1.125rem;
      font-weight: 600;
      color: var(--accent);
      margin-bottom: 0.75rem;
    }
  }
}

.text-span-accent {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
