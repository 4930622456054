.main-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5.5em 2em;
  display: flex;
  position: relative;

  &__grid {
    display: grid;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    grid-template-rows: auto;
    align-items: center;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;

    @media #{$mediumAbove} {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2.5em;
      grid-row-gap: 2.5em;
    }

    &__image {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      img {
        border-radius: var(--border-radius);
        width: 80%;
        object-fit: cover;
        vertical-align: middle;
        display: inline-block;

        @media #{$mediumAbove} {
          width: 90%;
        }
      }
    }

    &__content {
      grid-row-gap: 2.5em;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;

      background-color: var(--white);
      padding: 2em 1em;
      border-radius: var(--border-radius);
      box-shadow: 0 17px 14px -16px rgba(0, 0, 0, 0.05),
        0 40px 47px -26px rgba(0, 0, 0, 0.05);
      margin-top: -10em;

      @media #{$mediumAbove} {
        padding: 0;
        background-color: transparent;
        opacity: 1;
        box-shadow: none;
        margin-top: 0;
      }

      &__header {
        color: var(--header);
        letter-spacing: -0.01em;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 2em;
        font-weight: 700;
        text-align: center;

        @media #{$largeAbove} {
          font-size: 3em;
        }
      }

      &__paragraph {
        padding: 0 1em;
        text-align: center;

        @media #{$mediumAbove} {
          text-align: left;
        }
      }

      &__bullet {
        grid-row-gap: 0.5em;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        padding: 0 1em;

        &__header {
          margin-top: 0.05em;

          > span {
            margin-right: 0.5em;
          }
        }
      }
    }
  }
}
